.login__container {
    border       : 5px solid rgb(255, 103, 1);
    border-radius: 10px 10px 10px 10px;
    display      : flex;
    flex-flow    : column;
    align-items  : center;
    margin       : auto;
    width        : 45vw;
    text-align   : center;
    margin-top   : 10vh;
}

.login__pic-wrap {
    position     : relative;
    margin-top   : 5%;
    width        : 10vw;
    padding-top  : 10vw;
    overflow     : hidden;
    border-radius: 2px 2px 2px 2px;
   
}

.login__img {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    display   : block;
    width     : 100%;
    max-width : 100%;
    height    : 100%;
    max-height: 100%;
    object-fit: cover;
}


.login__add__user {
    display    : flex;
    flex-flow  : column;
    align-items: center;
    margin     : auto;
    width      : 40vw;
    text-align : center;
    margin-top : 2vh;
}

.login__item {
    width    : 90%;
    outline  : 0;
    border   : 0;
    font-size: 25px;
}

.login__item__label {
    font-size: 25px;
    color    : rgb(255, 103, 1);
}

.login__footer {
    display              : grid;
    grid-template-columns: repeat(2, auto);
    grid-gap             : 1rem;
    margin-top           : 2rem;
    margin-bottom        : 2rem;
}


@media only screen and (max-width: 960px) {
    .login__container {
        width: 70vw;
    }

    .login__pic-wrap {
        width      : 16vw;
        padding-top: 16vw;
    }

}

@media only screen and (max-width: 630px) {
    .login__container {
        width         : 75vw;
        padding-bottom: 3vh;
    }

    .login__item {
        width    : 80%;
        font-size: 20px;
    }

    .login__item__label {
        font-size: 20px;
    }
    .login__pic-wrap {
        width      : 22vw;
        padding-top: 22vw;
    }
}

@media only screen and (max-width: 500px) {
    .login__container {
        width         : 85vw;
        border        : 0px;
        padding-bottom: 4vh;
    }

    .login__item {
        width    : 80%;
        font-size: 20px;
    }

    .login__item__label {
        font-size: 20px;
    }

    .login__pic-wrap {
        width      : 25vw;
        padding-top: 25vw;
    }
}
:root {
    --bg           : #f4f7fa;
    --bg-accent    : #484a4d;
    --text-color   : #dadce1;
    --nav-size     : 60px;
    --border       : 1px solid #c95000;
    --border-radius: 8px;
    --speed        : 500ms;
}

/* Dropdown Menu */

.dropdown {
    margin-left     : auto;
    position        : absolute;
    background-color: var(--bg);
    border          : var(--border);
    border-radius   : var(--border-radius);
    overflow        : hidden;
    height          : attr(height);
    transition      : all var(--speed) ease;
    z-index         : 100;
}

.dropdown span {
    display: flex;
    width  : auto;
}

.down_icon {
    margin    : auto 10px auto auto;
    font-size : 20px;
    transform : rotate(0deg);
    transition: transform 0.2s ease;
    cursor    : pointer;
}

.left_icon {
    margin    : auto 10px auto auto;
    font-size : 20px;
    transform : rotate(90deg);
    transition: transform 0.2s ease;
    cursor    : pointer;
}

.menu {
    width: 100%;
}

.menuItem {
    height       : 50px;
    display      : flex;
    align-items  : center;
    border-radius: var(--border-radius);
    padding      : 0.5rem;
    color        : var(--secondary);
    cursor       : pointer;
    transition   : color 0.3s ease-in-out;
}

.menuItem:hover {
    text-decoration: none;
    color          : var(--main);
}

.menu-item .iconButton {
    margin-right: 0.5rem;
}

.menu-item .iconButton:hover {
    filter: none;
}

.menu-item:hover {
    background-color: #525357;
}

.iconRight {
    margin-left: auto;
}
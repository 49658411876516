.drag-drop__files input {
    outline           : 2px dashed var(--main);
    outline-offset    : -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition        : outline-offset .15s ease-in-out, background-color .15s linear;
    padding           : 120px 0px 85px 35%;
    text-align        : center !important;
    margin            : 0;
    width             : 100%;
}

.drag-drop__files input:focus {
    outline           : 2px dashed #313131;
    outline-offset    : -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition        : outline-offset .15s ease-in-out, background-color .15s linear;
}

.drag-drop__files {
    position         : relative;
}


.drag-drop__files:after {
    pointer-events   : none;
    position         : absolute;
    top              : 60px;
    left             : 0;
    width            : 50px;
    right            : 0;
    height           : 56px;
    content          : "";
    background-image : url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display          : block;
    margin           : 0 auto;
    background-size  : 100%;
    background-repeat: no-repeat;
}

.drag-drop__color input {
    background-color: #f1f1f1;
}

.drag-drop__files:before {
    position      : absolute;
    bottom        : 10px;
    left          : 0;
    pointer-events: none;
    width         : 100%;
    right         : 0;
    height        : 57px;
    content       : " drag your files";
    display       : block;
    margin        : 0 auto;
    color         : var(--main);
    font-weight   : 600;
    text-transform: capitalize;
    text-align    : center;
}

img {
  max-width: 100%;
  height: 100%;
}

.change__photo__input[type="file"] {
  display: none;
}

.change__photo__file__upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background:  rgb(255, 103, 1);
  margin-bottom: 25px;
}

.change__photo__img__wrap{
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.change__photo__img__upload:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(255, 103, 1);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.change__photo__img__upload:hover:before{
 opacity: 1;
}
.change__photo__img__upload.delete:before{
  content: "\f057";
  color: rgb(61, 61, 61);
 
}
.change__photo__img__upload.delete:hover:before{
 opacity: 1;
}

.change__photo__delete__button{ 
  background: transparent;
  border: none;
  position: absolute;
  display: flex;
}


.change__photo__upload__button {
  width           : 15vw;
  border          : 2px solid rgb(255, 103, 1);
  background-color: white;
  color           : grey;
  font-size       : 25px;
  border-radius   : 5px 5px 5px 5px;
  cursor          : pointer;
  margin          : 2vw;
}

.change__photo__button:hover {
  background-color: rgb(255, 103, 1);
  color           : white;
  font-size       : 25px;
}
.tab-info-files-container {
    margin         : auto;
    margin-top     : 1rem;
    margin-bottom  : 1rem;
    width          : 60%;
    box-shadow     : 2px 4px 4px 8px rgba(27, 27, 27, 0.171);
    -webkit-filter : drop-shadow(0 6px 20px rgba(146, 146, 146, 0.959));
    filter         : drop-shadow(0 6px 20px rgba(114, 114, 114, 0.678));
    border-radius  : 10px;
    overflow       : hidden;
    text-decoration: none;
    cursor         : grab;
    transition     : 0.2s ease-in-out;
}

.tab-info-files-container:hover {
    transform : scale(1.1);
    transition: 0.2s ease-in-out;
}

.tab-info-grid {
    display              : grid;
    grid-template-columns: repeat(2, auto);
    list-style           : none;
    text-decoration      : none;
}

.tab-info-grid.triple {
    grid-template-columns: repeat(3, auto);
}

.tab-info-grid>li>.tab-icon,
.tab-info-grid>li>.edit-icon {
    font-size  : 32px;
    color      : var(--wrong);
    transition : 0.2s ease-in-out;
    float      : left;
    padding-top: 10px;
    margin-left: 10px;

}

.tab-info-grid>li>.edit-icon {
    color       : var(--main);
    float       : right;
    margin-right: 10px;
}

.tab-info-grid>li>.edit-icon:hover,
.tab-info-grid>li>.tab-icon:hover {
    cursor    : pointer;
    color     : var(--secondary);
    transform : rotate(180deg) scale(1.2) translateY(-10px);
    transition: 0.5s ease-in-out;
}


.tab-info-grid>.text {
    font-size: 32px;
}


.edit-tab-input {
    width        : 60%;
    margin       : auto;
    margin-bottom: 3rem;
}

.edit-tab-input>.error {
    color     : var(--wrong);
    text-align: center;
    font-size : 20px;
    width     : 60%;
}

@media only screen and (max-width: 960px) {
    .tab-info-files-container{
        width          : 85%;
    }

    .tab-info-grid>li>.edit-icon,
    .tab-info-grid>li>.tab-icon {
        font-size   : 25px;
        padding-top : 5px;
        margin-left : 5px;
        margin-right: 5px;
    }

    .tab-info-grid>.text {
        font-size: 25px;
    }
}
:root {
  --main     : rgb(255, 103, 1);
  --secondary: #5c5c5c;
  --hero : rgba(255,143,0,1);
  --islab: rgb(255, 255, 255);
  --wrong  : rgb(255, 0, 0);
  --warning: rgb(255, 251, 0);
  --success: rgb(13, 128, 2);

  --font-title : 32px;
  --font-subtitle : 25px;
  --font-text : 18px;

  /* Thin Scrollbar */
  scrollbar-color:  var(--secondary) var(--main)!important;
  scrollbar-width: 5px ;
}

html,body {
  min-height: 100vh;
  position  : relative;
}

.loader {
  margin           : auto;
  margin-top       : 20vh;
  margin-bottom    : 40vh;
  border           : 16px solid transparent;
  border-radius    : 90%;
  border-top       : 20px solid var(--secondary);
  border-bottom    : 20px solid var(--main);
  width            : 120px;
  height           : 120px;
  -webkit-animation: spin 1.5s linear infinite;
  animation        : spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-track {
  border-radius     : 10px;
  background-color  : var(--main);
}

::-webkit-scrollbar {
  width           : 5px;
  background-color: var(--main);
}

::-webkit-scrollbar-thumb {
  border-radius     : 10px;
  background-color  : var(--secondary);
}



* {
  box-sizing : border-box;
  margin     : 0;
  padding    : 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display        : flex;
  height         : 90vh;
  align-items    : center;
  justify-content: center;
  font-size      : 3rem;
}

.services {
  /*background-image: url('/images/image-2.jpg');*/
  background-position: center;
  background-size    : cover;
  background-repeat  : no-repeat;
  color              : #fff;
  font-size          : 100px;
}

.products {
  /*background-image: url('/images/image-1.jpg');*/
  background-position: center;
  background-size    : fill;
  background-repeat  : no-repeat;
  color              : #fff;
  font-size          : 100px;
}

.sign-up {
  /*background-image: url('/images/image-8.jpg');*/
  background-position: center;
  background-size    : cover;
  background-repeat  : no-repeat;
  color              : #fff;
  font-size          : 100px;
}

.main_title {
  color      : var(--main);
  font-family: 'Permanent Marker', cursive;
  margin-top : 2rem;
  font-size  : 50px;
  text-align : center;
}

/*icon for mobile navbar*/

.fa-times {
  color    : #fff;
  font-size: 2rem;
}

.fa-bars {
  color: #fff;
}
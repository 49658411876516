* {
    box-sizing: border-box;
}

:root {
    --duration: 15s
}

@-webkit-keyframes ticker2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
        visibility       : visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform        : translate3d(-100%, 0, 0);
    }
}

@keyframes ticker2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
        visibility       : visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform        : translate3d(-100%, 0, 0);
    }
}

.slideContainer {
    position  : relative;
    width     : 100%;
    background: rgba(0, 0, 0, 0.801);
}


.itemsSlide {
    width     : 90%;
    margin    : auto;
    text-align: center;
}


.arrow {
    position     : absolute;
    outline      : none;
    transition   : all 0.5s;
    border-radius: 35px;
    z-index      : 100;
    border       : none;
    background   : rgba(0, 0, 0, 0.1);
    min-width    : 43px;
    min-height   : 43px;
    opacity      : 1;
    cursor       : pointer;
}

.arrow:hover {
    background: var(--main);
    opacity: 0.8;
}

.arrow::before {
    font-size  : 20px;
    color      : #fff;
    display    : block;
    font-family: revicons;
    text-align : center;
    z-index    : 2;
    position   : relative;
}

.arrowLeft {
    left: calc(4% + 1px);
}

.arrowLeft:hover {
    transform                        : scale(1.2);
    animation                        : right-left 1s ease-in-out;
    -webkit-animation                : right-left 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.arrowLeft::before {
    content: "\e824";
}

.arrowRight {
    right: calc(4% + 1px);
}

.arrowRight:hover {
    transform                        : scale(1.2);
    animation                        : left-right 1s ease-in-out;
    -webkit-animation                : left-right 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.arrowRight::before {
    content: "\e825";
}
@keyframes left-right {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}

@keyframes right-left {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(-10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}



.pic_wrap {
    display      : inline-block;
    border       : 1px solid var(--main);
    position     : relative;
    width        : 150px;
    height       : 150px;
    overflow     : hidden;
    border-radius: 50%;
    margin       : 10px;
    transition   : all 0.3s linear;
    cursor       : pointer;
}

.pic {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    display   : block;
    width     : 100%;
    object-fit: cover;
    transition: all 0.3s linear;
}

.pic_wrap:hover,
.pic:hover {
    transform: scale(1.05);
}


@media only screen and (max-width: 450px) {
    .pic_wrap {
        width : 120px;
        height: 120px;
    }
}
:root {
  --primary: #fff;
}

.my__switch{
  transform: scale(1.5);
  padding: 10px;
  background-color: var(--main);
  color: chocolate;
}

.my__btn {
  padding      : 8px 20px;
  border-radius: 2px;
  outline      : none;
  border       : none;
  cursor       : pointer;
}

.btn--primary {
  background-color: var(--primary);
  color           : #242424;
  border          : 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color           : #fff;
  padding         : 8px 20px;
  border          : 1px solid var(--primary);
  transition      : all 0.3s ease-out;
}

.btn--save {
  border       : 2px solid var(--main);
  border-radius: 5px 5px 5px 5px;
  color        : #464646;
  background   : #fff;
  transition: all 0.3s ease-in-out;
}

.btn--save:hover {
  background: var(--main);
  color     : #fff;
  transition: all 0.3s ease-in-out;
}

.btn--cas {
  border       : 2px solid #0819b1;
  border-radius: 5px 5px 5px 5px;
  color        : #464646;
  background   : #fff;
  transition: all 0.4s ease-in-out;
}

.btn--cas:hover {
  background: #0819b1;
  color     : #fff;
  transition: all 0.4s ease-in-out;
}


.btn--sm {
  padding  : 0.5vh 2vw;
  cursor   : pointer;
  font-size: 18px;
}

.btn--md {
  padding  : 0.5vh 3vw;
  cursor   : pointer;
  font-size: 20px;
}

.btn--lg {
  padding  : 1vh 5vw;
  cursor   : pointer;
  font-size: 22px;
}


.btn--medium {
  padding  : 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding  : 12px 26px;
  font-size: 22px;
}

.btn--medium:hover,
.btn--large:hover {
  background: #fff;
  color     : #242424;
  transition: 250ms;
  cursor    : pointer;
}


.double-button {
  padding: 5%;
  display: inline block;

}

@media only screen and (min-width: 1400px) {
  .btn--md {
    padding  : 1vh 5vw;
    font-size: 22px;

  }
}

@media only screen and (max-width: 460px) {
  .btn--md {
    padding  : 0.5vh 2vw;
    font-size: 18px;
  }
}
.container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 95%;
    margin: 0 auto;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0 45px;
}

.card {
    display: flex;
    flex: 1;
    margin: 1rem 1rem;
    border-radius: 10px;
    height: 250px;
    width: 250px;
    background-image: url('/src/images/footer-background.jpg');
    transition: all 0.5s ease-in-out;
}

.card:hover {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
}

.card_link {
    display: flex;
    flex: 1;
    border-radius: 10px;
}

.card_picWrap {
    position: relative;
    overflow: hidden;
    width:100%;
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
}

.card:hover .card_picWrap {
    opacity: 0;
}

.card_info {
    position: absolute;
    opacity: 0;
    color: #fff;
    transition: opacity 0.8s ease-in-out;
    height: 250px;
    width: 250px;
}

.card:hover .card_info {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
}

.text {
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
}

.name {
    font-size: 25px;
    margin-bottom: 120px;
}

.icon {
    margin-right: 5px;
    margin-bottom: -4px;
    color: rgb(255, 103, 1);
}


@media only screen and (max-width: 1300px) {
    .card_info, 
    .card {
        height: 250px;
        width: 250px;
    }
}

@media only screen and (max-width: 1200px) {
    .wrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
    }
}

@media only screen and (max-width: 1100px) {
    
    .name {
        font-size: 25px
    }
}

@media only screen and (max-width: 900px) {
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 600px) {
    .wrapper {
        display: grid;
        grid-template-columns: repeat(1, auto);
    }
}



.container {
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 30% 50%);
    width: 95%;
    margin: auto;
    align-items: center;
}

.img_wrap {
    position: relative;
    width: 250px;
    height: 250px;
    overflow: hidden;
    margin: auto;
    border-radius: 50%;
    border: 4px solid rgb(255, 103, 1);
    margin: 1rem;
}

.item_wrap {
    margin: 1rem;
}

.item {
    list-style: none;
    margin-top: 1rem;
    border-bottom: 3px solid rgb(255, 103, 1);
    font-size: 20px;
}

.icon {
    color: rgb(255, 103, 1);
    margin-right: 5px;
}

.iconSpecial{
    margin-left: 50px;
    height: 100px;
}

.ariel {
    margin: 30px;
    font-size: 50px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    color: #333;
    transition: 0.2s ease-in-out;
}

.ariel:hover {
    color: rgb(255, 103, 1);
    cursor: pointer;
    font-size: 50px;
    transform: scale(1.2);
}

@media only screen and (max-width: 1300px) {
    .img_wrap {
        position: relative;
        width: 200px;
        height: 200px;
        overflow: hidden;
        margin: auto;
        border-radius: 50%;
        border: 4px solid rgb(255, 103, 1);
        margin: 1rem;
    }
    .grid {
        grid-template-columns: repeat(2, 30% 60%);
    }
}

@media screen and (max-width : 800px) {
    .grid {
        grid-template-columns: repeat(2, 35% 60%);
    }
}
@media screen and (max-width : 700px) {
    .grid {
        grid-template-columns: repeat(2, 40% 60%);
    }
}

@media only screen and (max-width: 600px) {
    .grid {
        grid-template-columns: repeat(1, auto);
        width: 95%;
    }
}

.my-user-card-background {
    margin-left: 250px;
}

.my-user-card-container {
    border       : 5px solid rgb(255, 103, 1);
    border-radius: 10px 10px 10px 10px;
    display      : flex;
    flex-flow    : column;
    align-items  : center;
    margin       : auto;
    width        : 70%;
    margin-top   : 2vh;
    margin-bottom  : 2vh;
    text-align   : left;
}

.my__user__card__container {
    border       : 5px solid rgb(255, 103, 1);
    border-radius: 10px 10px 10px 10px;
    display      : flex;
    flex-flow    : column;
    align-items  : center;
    margin-left  : 35vw;
    margin-right : 20vw;
    margin-top   : 2vh;
    margin-bottom  : 2vh;
    text-align   : left;
}


.my__user__card__pic-wrap {
    position     : relative;
    margin-top   : 5%;
    width        : 20vw;
    padding-top  : 20vw;
    overflow     : hidden;
    border-radius: 100%;
    border       : 3px solid rgb(255, 103, 1);
}

.fade-img {
    animation-name    : fade-img;
    animation-duration: 2s;
}

.my__user__img {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    display   : block;
    width     : 100%;
    max-width : 100%;
    height    : 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.my__user__img:hover {
    transform: scale(1.1);
}

.my__user__card__item__wrapper {
    margin-top   : 15px;
    border-bottom: 3px solid rgb(255, 103, 1);
    width        : 32vw;
    text-align   : left;
}

.my__user__card__item__wrapper__description {

    border       : 3px solid rgb(255, 103, 1);
    height       : 20vh;
    width        : 32vw;
    border-radius: 10px 10px 10px 10px;

}

.my__user__card__item {
    width    : 90%;
    outline  : 0;
    border   : 0;
    font-size: 25px;
}

.my__user__card__item__description {
    width        : 90%;
    height       : 98%;
    outline      : 0;
    border       : 0;
    font-size    : 18px;
    border-radius: 10px 10px 20px 10px;

}

.my__user__card__item__label {
    font-size: 25px;
    color    : rgb(255, 103, 1);

}

.my__user__card__item__label__description {
    margin-top: 15px;
    text-align: left;
    font-size : 25px;
    color     : rgb(255, 103, 1);

}

.my__user__card__button {
    width           : 15vw;
    border          : 2px solid rgb(255, 103, 1);
    background-color: white;
    color           : grey;
    font-size       : 25px;
    border-radius   : 5px 5px 5px 5px;
    cursor          : pointer;
    margin          : 2vw;
}

.my__user__card__button:hover {
    background-color: rgb(255, 103, 1);
    color           : white;
    font-size       : 25px;
}

.my__user__card__check {
    float    : right;
    margin   : auto;
    font-size: 20px;
    position : absolute;
}

.my__user__card__check.correct {
    color: green;
}

.my__user__card__check.warning {
    color: rgb(224, 136, 4);
}

.my__user__card__check.wrong {
    color: red;
}

.my__user__return__button {
    font-size  : 25px;
    color      : rgb(255, 103, 1);
    position   : absolute;
    margin-top : 80px;
    margin-left: 37vw;
}

.my__user__return__button:hover {
    color: grey;
}

.my__user__edit__img {
    color    : rgb(255, 103, 1);
    font-size: 30px
}


@media only screen and (min-width: 1400px) {
    .my-user-card-container {
        width: 60%;
    }
}

@media only screen and (max-width: 1200px) {
    .my-user-card-container {
        width: 80%;
    }

    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (max-width: 960px) {
    .my-user-card-background {
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .my-user-card-background {
        margin-left: 0;
    }

    .my-user-card-container {
        width: 90%;
    }
}

@media only screen and (max-width: 460px) {
    .my-user-card-container {
        border: none;
    }
}


@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1250px) {
    .my__user__card__container {
        margin-left : 35vw;
        margin-right: 12vw;
    }

    .my__user__card__item__wrapper,
    .my__user__card__item__wrapper__description {
        width: 40vw;
    }

    .my__user__card__pic-wrap {
        width      : 25vw;
        padding-top: 25vw;

    }
}

@media only screen and (max-width: 960px) {
    .my__user__return__button {
        margin-left: 12vw;
    }

    .my__user__card__container {
        margin-left : 10vw;
        margin-right: 10vw;
    }

    .my__user__card__item__wrapper,
    .my__user__card__item__wrapper__description {
        width: 60vw;
    }


    .my__user__card__pic-wrap {
        width      : 30vw;
        padding-top: 30vw;
    }

    .my__user__card__item__description {
        font-size: 15px;
    }

    .my__user__card__item,
    .my__user__card__item__label {
        font-size: 20px;
    }

    .my__user__card__button:hover,
    .my__user__card__button {
        width: 28vw;
    }


    .my__user__card__item__label__description {
        margin-top: 15px;
        font-size : 20px;
    }

}

@media only screen and (max-width: 500px) {
    .my__user__card__container {
        margin-left : 5vw;
        margin-right: 5vw;
    }

    .my__user__card__item__wrapper {
        width: 70vw;
    }

    .my__user__card__button:hover,
    .my__user__card__button {
        width    : 30vw;
        font-size: 18px;
    }

    .my__user__card__item__wrapper__description {
        width: 70vw;
    }


    .my__user__card__pic-wrap {
        width      : 40vw;
        padding-top: 40vw;

    }
}
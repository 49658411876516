.side__menu {
    margin-top      : 80px;
    background      : rgb(255, 103, 1);
    background-image: url('/src/images/header_manage_rotate.png');
    width           : 250px;
    height          : 100vh;
    display         : flex;
    justify-content : center;
    position        : fixed;
    top             : 0;
    transition      : 350ms;
    z-index         : 500;

}

.side__text {
    display    : flex;
    align-items: center;
    padding    : 8px 0px 8px 16px;
    list-style : none;
    height     : 50px;
    z-index    : 500;
}

.side__text a {
    text-decoration: none;
    color          : #f5f5f5;
    font-size      : 18px;
    width          : 95%;
    height         : 100%;
    display        : flex;
    align-items    : center;
    padding        : 0 16px;
    border-radius  : 4px;
    z-index        : 500;
    transition     : all 0.2s ease-in-out;
}

.side__text a:hover {
    background-color: #5c5c5c;
    text-decoration : none;
    color           : #fff;
    z-index         : 500;
    transition      : all 0.2s ease-in-out;
    transform       : translateX(-20px) scale(1.2);
}

.side__menu__items {
    width          : 100%;
    justify-content: left;
    align-items    : left;
    z-index        : 500;
}


.span {
    margin-left: 16px;
}

@media screen and (max-width: 960px) {
    .side__menu {
        display       : flex;
        flex-direction: column;
        width         : 100%;
        height        : 90vh;
        position      : absolute;
        left          : -100%;
        opacity       : 1;
        transition    : all 0.5s ease;
    }

    .side__menu.active {
        background: rgb(253, 122, 0);
        left      : 0;
        opacity   : 1;
        transition: all 0.5s ease;
        z-index   : 1;
    }


}
.heroContainer {
    /*     background-image: linear-gradient(to bottom, #5c5b5a, #816346, #a76832, #cf691c, #fb6300);*/
    background-image: url('/src/images/polygon.png');
    opacity: 0.25;
    background-repeat: repeat;
    background-color: var(--hero);
    background-blend-mode:soft-light;
    max-height: auto;
    width     : 100%;
    object-fit: cover;
    position  : relative;
    text-align: center;
    opacity:10%;}


.heroWrapText,
.heroWrap {
    width          : 90%;
    display        : inline-flex;
    text-align     : center;
    justify-content: center;
    padding        : 5%;
    margin         : auto
}


.sliderContainer,
.islabTitleContainer {
    margin    : auto;
    width     : 70%;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .islabTitleContainer {
        width: 80%;
    }

    .sliderContainer {
        width: 90%;
    }
}



@media screen and (max-width: 960px) {
    .islabTitleContainer {
        width: 80%;
    }

    .sliderContainer {
        width: 90%;
    }
}

@media screen and (max-width: 700px) {

    .heroWrapText,
    .heroWrap {
        width        : 100%;
        padding-left : 0;
        padding-right: 0;
    }

    .islabTitleContainer {
        width: 90%;
    }
}
.container {
    margin: 50px 0px 0px 250px;
    height: 100vh;
}

.dropzone_container {
    margin    : auto;
    width     : 80%;
    height    : 100%;
    text-align: center;
}


.dropzone {
    align-items: center;
    width      : 90%;
    margin     : auto;
    height     : 100%;
}

.add_icon {
    margin    : 2% 0 0 50%;
    color     : var(--main);
    font-size : 40px;
    cursor    : pointer;
    transition: all 0.5s ease-in-out;
}
.add_icon:hover {
    margin                           : 2% 0 0 50%;
    color                            : var(--secondary);
    font-size                        : 40px;
    transform                        : scale(1.1) rotate(360deg);
    animation                        : up-down 1s ease-in-out;
    -webkit-animation                : up-down 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;

}

/* Add Animation */
@keyframes up-down {
    from {
        transform: translateY(0px) scale(1.1);
    }

    50% {
        transform: translateY(10px) scale(0.8);

    }

    to {
        transform: translateY(0px) scale(1.1);
    }
}

@-webkit-keyframes up-down {
    from {
        -webkit-transform: translateY(0px) scale(1.1);
    }

    50% {
        -webkit-transform: translateY(10px) scale(0.8);

    }

    to {
        -webkit-transform: translateY(0px) scale(1.1);
    }
}


@media screen and (max-width: 1500px) {
    .dropzone_container {
        width: 90%;
    }
}

@media screen and (max-width: 1250px) {
    .dropzone_container {
        width: 100%;
    }

    .dropzone {
        width: 98%;
    }
}

@media screen and (max-width: 960px) {

    .container {
        margin: 50px 0px 0px 0px;
    }
}
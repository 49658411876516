.container {
    margin       : auto;
    overflow     : hidden;
    margin-bottom: 2rem;
    /* box-shadow   : 10px 10px 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.19); */
    /* background: rgba(0, 0, 0, 0.1); */
    transition   : all 0.5s ease-in-out;
    width: 100%;
    justify-content: left;
}



.title {
    display              : grid;
    grid-template-columns: repeat(2, auto);
}

.text {
    color         : var(--main);
    font-size     : var(--font-title);
}


.icon {
    color       : var(--secondary);
    position    : relative;
    font-size   : 20px;
    float       : right;
    margin-right: 1rem;
    margin-top  : 2rem;
    transition  : all 0.3s ease-in-out;
    transform   : rotate(90deg);
    cursor      : pointer;
}




.wrapper {
    position  : relative;
    overflow-y: auto;
    height    : 80%;
    border    : 16px;
    transition: all 0.5s ease-in-out;
    margin: 2% 0 2% 0;
}



.transition {
    transform: translateX(-250px);
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .text {
        color         : var(--main);
        font-size     : 30px;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 960px) {
    .text {
        color         : var(--main);
        font-size     : 28px;
        text-transform: uppercase;
    }
}


@media only screen and (max-width: 960px) {
    .container {
        width: 90%;
        margin-left: 5%;
    }

}
@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
        margin: auto;
    }
}


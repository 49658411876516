.container {
    position: absolute;
    min-height: 200vh;
    max-height: 2000vh;
    z-index: -10;
}

.page {
    position: fixed;
    height: 2500px;
    background: rgba(160, 13, 13, 0.226);
    z-index: -1000;
}

.text {
    width: 30%;
    font-size: large;
    text-align: center;
}

.newsContainer {
    display: inline-flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;

}

.feedContainer,
.twitterContainer {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 25%;
    margin-left: 1%;
    margin-right: 1%;
}

.projectCardContainer {
    width: 75%;
    margin-top: 2rem;
    margin-bottom: 3rem;

}

@media only screen and (max-width: 1200px) {

    .feedContainer,
    .twitterContainer {
        width: 33%;
    }
}

@media only screen and (max-width: 960px) {

    .feedContainer,
    .twitterContainer,
    .projectCardContainer {
        margin: 20px auto 20px auto;
        width: 90%;
    }
}


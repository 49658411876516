
.footerContainer{
    background-image: url('/src/images/footer-background.jpg');
    color          : #fff;
    height         : 200px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    left           : 0;
    bottom         : 0;
    z-index        : -1;
    position       : absolute;
    width          : 100%;
    top            : 100%;
    left           : 0;
    transform      : translateY(-100%); 
    border-top: 5px solid  var(--main);
}


.footerWrapper {
    display              : grid;
    grid-template-columns: repeat(4, auto);
    justify-content      : space-evenly;
    align-items          : center;
    width                : 100vw;
}

.footerWrapper>div {
    text-align: left;
    font-size : 25px;
}

.item {
    margin-top: 1rem;
}

.icon {
    font-size: 35px;
}

.link,
.logo {
    cursor    : pointer;
    transition: all 0.5s ease-in-out;
    color     : #fff;
}

.link:hover,
.logo:hover {
    transform: scale(1.2);
    color    : var(--main);
}

.arrowIcon {
    color     : var(--main);
    font-size : 28px;
    position  : absolute;
    top       : 0;
    margin-top: 1vh;
    transition: all 0.2s ease-in-out;
}

.arrowIcon:hover {
    color                            : var(--secondary);
    cursor                           : pointer;
    animation                        : up-down 1s ease-in-out;
    -webkit-animation                : up-down 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}


/* Add Animation */

@keyframes up-down {
    from {
        -webkit-transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(10px);
    }

    to {
        -webkit-transform: translateY(0px);
    }
}


@media screen and (max-width: 1100px) {
    .footerContainer {
        height: 400px
    }

    .footerWrapper {
        grid-template-columns: repeat(2, auto);
    }

    .maps {
        padding-top: 1rem;
        height: 200px;
    }

    .icon {
        font-size: 25px;
    }

    .arrowIcon {
        right       : 0;
        margin-right: 10vw;
        margin-top: 2vh;
    }
}

@media screen and (max-width: 550px) {
    .footerWrapper {
        grid-template-columns: repeat(1, auto);
    }

    .maps {
        height: 150px;
    }

    .footerWrapper>div {
        text-align: left;
        font-size : 18px;
    }

    .icon {
        font-size: 20px;
    }

    .logo {
        height: 50px;
    }

    .arrowIcon {
        right       : 0;
        margin-right: 10vw;
    }
}
.container {
    height    : auto;
    width     : 100%;
    background: rgba(0, 0, 0, 0.925);
    position  : fixed;
    bottom    : 0;
    border-top: 2px solid var(--main);
    z-index: 99999;

}

.wrapper {
    padding        : 1rem;
    width          : 80%;
    margin         : auto;
    color          : white;
    justify-content: center;
    text-align     : center;
}

.btn {
    margin    : 1rem;
    color     : white;
    background: var(--main);
    outline: none;
    border: none;
    border-radius: 5px ;
    font-size: var(--font-text);
    padding: .5rem 2rem .5rem 2rem;
    cursor: pointer;
    transition : all .3s ease-in-out
}

.btn:hover{
    background: var(--primary);
    color: var(--main);
    transform: scale(1.1);
    border-radius: 20px;
}
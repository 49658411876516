.background {
    display       : flex;
    position      : fixed;
    top           : 0;
    bottom        : 0;
    left          : 250px;
    padding-top   : 6%;
    padding-bottom: 6%;
    width         : calc(100% - 250px);
    background    : rgba(0, 0, 0, 0.6);
    z-index       : 1000;
    overflow      : auto;
}

.backgroundSidebar {
    display         : block;
    /* Hidden by default */
    position        : fixed;
    /* Stay in place */
    z-index         : 1000;
    /* Location of the box */
    left            : 0;
    top             : 0;
    width           : 250px;
    /* Full width */
    height          : 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.wrapper {
    position                  : relative;
    background-color          : #fefefe;
    border-radius             : 20px 20px 20px 20px;
    margin                    : auto;
    width                     : 70%;
    box-shadow                : 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name    : animatetop;
    -webkit-animation-duration: 0.6s;
    animation-name            : animatetop;
    animation-duration        : 0.6s
}

.wrong {
    padding   : 20px;
    color     : white;
    opacity   : 1;
    transition: opacity 0.6s;
    position  : relative;
    width     : 20%;
    margin    : auto;
    margin-top: 10vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: var(--wrong);
}

.waring {
    border    : none;
    color     : #fff;
    background: var(--warning);
}

.correct {
    background: var(--warning);
    border    : 4px solid var(--primary);
}

.header {
    padding      : 2px 16px;
    border-radius: 5px 5px 0 0;
    color        : grey;
    text-align   : left;
}

.body {
    position       : relative;
    margin         : auto;
    width          : 100%;
    position       : relative;
    justify-content: center;
    align-items    : center;
    text-align     : center;
}

.footer {
    float: right;
    margin-top   : 3rem;
    margin-bottom: 2rem;
    padding      : 2px 16px;
    border-radius: 0 0 5px 5px;
    font         : 20vw;
    color        : white;
}


.closeBtn {
    font-size  : bold;
    color      : grey;
    float      : right;
    font-size  : 38px;
    font-weight: bold;
    position   : relative;
    z-index    : 1000;
    transition: all 0.5s ease-in-out;
}

.closeBtn:hover,
.closeBtn:focus {
    color          : var(--main);
    text-decoration: none;
    cursor         : pointer;
    transform      : rotate(360deg);
}


@media only screen and (min-width: 1400px) {
    .wrapper {
        width: 60%;
    }
}

@media only screen and (max-width: 1200px) {
    .wrapper {
        width: 80%;
    }
}

@media only screen and (max-width: 960px) {
    .background {
        left : 0;
        width: 100%;
    }

    .backgroundSidebar {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .wrapper {
        width: 90%;
    }
}

@media only screen and (max-width: 460px) {
    .wrapper {
        border: none;
    }
}




/* forse fuori */

@media only screen and (max-width: 1250px) {
    .modal__content {
        margin-left : 35vw;
        margin-right: 12vw;
    }
}

@media only screen and (max-width: 960px) {
    .modal__content {
        margin-left : 10vw;
        margin-right: 10vw;
    }
}

@media only screen and (max-width: 500px) {
    .modal__container {
        padding-top: 20%;
    }

    .modal__content {
        margin-left : 5vw;
        margin-right: 5vw;
    }
}
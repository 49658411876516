.addIcon {
    position  : relative;
    display   : flex;
    margin    : auto !important;
    color     : var(--success);
    font-size : 32px;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
}

.addIcon:hover {
    cursor    : pointer;
    color     : var(--secondary);
    transform : scale(1.2);
    transition: 0.3s ease-in-out;
}


.close {
    position  : relative;
    display   : flex;
    margin    : auto !important;
    font-size : 32px;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
    transform: rotate(45deg);
    color    : var(--secondary);
}

.close:hover {
    cursor    : pointer;
    color     : var(--wrong);
    transform : scale(1.2) rotate(45deg);
    transition: 0.3s ease-in-out;
}
:root {
    --slideBackground: rgba(51, 51, 51, 0.658)
}

.sliderWrapper {
    width     : 60%;
    min-height: 60vh;
    max-height: auto;
    margin    : 2vh auto auto auto;
}

.slide {
    position      : relative;
    background    : var(--slideBackground);
    border-radius : 20px 20px 20px 20px;
    box-shadow    : 0 6px 20px rgba(12, 12, 12, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
    filter        : drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
}


.sliderTextSection {
    margin : auto;
    padding: 20px 90px 50px 90px;
}

a{
    color : var(--main);
    text-decoration: none;
}

a:hover{
    color : var(--main);
    text-decoration: none;
}



@keyframes left-right {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}

@keyframes right-left {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(-10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}



.answer {
    margin     : auto;
    width      : 100%;
    font-size  : 22px;
    color      : white;
    font-family: Kosugi Maru, sans-serif;
}

.arrow {
    position     : absolute;
    outline      : none;
    transition   : all 0.5s;
    border-radius: 35px;
    z-index      : 1000;
    border       : none;
    background   : rgba(0, 0, 0, 0.281);
    min-width    : 43px;
    min-height   : 43px;
    opacity      : 1;
    cursor       : pointer;
}

.arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}

.arrow::before {
    font-size  : 20px;
    color      : #fff;
    display    : block;
    font-family: revicons;
    text-align : center;
    z-index    : 2;
    position   : relative;
}

.arrowLeft {
    left: calc(4% + 1px);
}

.arrowLeft:hover {
    transform                        : scale(1.2);
    animation                        : right-left 1s ease-in-out;
    -webkit-animation                : right-left 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.arrowLeft::before {
    content: "\e824";
}

.arrowRight {
    right: calc(4% + 1px);
}

.arrowRight:hover {
    transform                        : scale(1.2);
    animation                        : left-right 1s ease-in-out;
    -webkit-animation                : left-right 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.arrowRight::before {
    content: "\e825";
}

.dotList {
    position       : absolute;
    bottom         : 0;
    display        : flex;
    left           : 0;
    right          : 0;
    justify-content: center;
    margin         : auto;
    padding        : 0;
    margin         : 0;
    list-style     : none;
    text-align     : center;
}

.dot {
    display      : inline-block;
    width        : 12px;
    height       : 12px;
    border-radius: 50%;
    opacity      : 1;
    padding      : 5px 5px 5px 5px;
    box-shadow   : none;
    transition   : all .5s;
    border-width : 2px;
    padding      : 0;
    margin       : 0;
    margin-right : 10px;
    margin-bottom: 20px;
    outline      : 0;
    cursor       : pointer;
    border       : none
}

.dot:hover {
    transition                       : all .5s;
    background                       : var(--secondary);
    animation                        : open-close .8s ease-in-out;
    -webkit-animation                : open-close .8s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.dotActive {
    display      : inline-block;
    width        : 15px;
    height       : 15px;
    border-radius: 20%;
    opacity      : 1;
    padding      : 5px 5px 5px 5px;
    box-shadow   : none;
    transition   : all .5s;
    border-width : 2px;
    padding      : 0;
    margin       : 0;
    margin-right : 6px;
    margin-bottom: 2px;
    outline      : 0;
    cursor       : pointer;
    background   : var(--main);
    border       : none;
}

@keyframes open-close {
    from {
        border-radius: 50%;
    }

    50% {
        border-radius: 20%;
    }

    to {
        border-radius: 50%;
    }
}

@media screen and (max-width: 960px) {
    .sliderTextSection {
        margin : auto;
        padding: 10px 10px 50px 10px;
    }

    .answer {
        font-size: 20px;
    }
}

@media screen and (max-width: 700px) {
    .answer {
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    .answer {
        font-size: 14px;
    }
}
.container {
    margin-left: 250px;
    margin-top : 10%;
}

.wrapper {
    margin: auto;
    width : 80%;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.wrapper:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.textAreaContainer,
.inputTextContainer {
    position     : relative;
    outline      : 0;
    text-align   : center;
    border-bottom: 4px solid var(--main);
    width        : 100%;
    text-align   : left;
}

.inputText {
    margin-top: 2rem;
    font-size : var(--font-text);
    outline   : none;
    border    : none;
    width     : 90%;
}

.textAreaContainer {
    border-bottom: none;

}

.textArea {
    align-items  : center;
    width        : 90%;
    margin-top   : 1rem;
    font-size    : var(--font-text);
    outline      : none;
    border       : none;
    border-radius: 10px 10px 10px 10px;
    height       : 350px;
    border       : 4px solid var(--main);
    resize       : none;
}

.cardContainer {
    margin         : auto;
    margin-top     : 1.5rem;
    margin-bottom  : 1.5rem;
    width          : 70%;
    box-shadow     : 2px 4px 4px 8px rgba(27, 27, 27, 0.171);
    -webkit-filter : drop-shadow(0 6px 20px rgba(146, 146, 146, 0.959));
    filter         : drop-shadow(0 6px 20px rgba(114, 114, 114, 0.678));
    border-radius  : 10px;
    overflow       : hidden;
    text-decoration: none;
    transition     : 0.2s ease-in-out;
}

.cardContainer:hover {
    transform : scale(1.1);
    transition: 0.2s ease-in-out;
}

.cardGrid {
    display              : grid;
    grid-template-columns: repeat(3, auto);
    list-style           : none;
    text-decoration      : none;
}

.deleteIcon {
    font-size  : 32px;
    color      : var(--wrong);
    transition : 0.2s ease-in-out;
    float      : left;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 10px;
}

.editIcon {
    font-size   : 32px;
    color       : var(--main);
    float       : right;
    margin-right: 10px;
    transition  : 0.2s ease-in-out;
    padding-top: 5px;
    padding-bottom: 5px;
}

.deleteIcon:hover,
.editIcon:hover {
    cursor    : pointer;
    color     : var(--secondary);
    transform : scale(1.2) translateY(-3px);
    transition: 0.5s ease-in-out;
}

.text {
    font-size: var(--font-subtitle);

}

.add_icon {
    margin    : 10% 0 5% 50%;
    color     : var(--main);
    font-size : 40px;
    cursor    : pointer;
    transition: all 0.5s ease-in-out;
}

.add_icon:hover {
    margin                           : 10% 0 5% 50%;
    color                            : var(--secondary);
    font-size                        : 40px;
    transform                        : scale(1.1) rotate(360deg);
    animation                        : up-down 1s ease-in-out;
    -webkit-animation                : up-down 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;

}

/* Add Animation */
@keyframes up-down {
    from {
        transform: translateY(0px) scale(1.1);
    }

    50% {
        transform: translateY(10px) scale(0.8);

    }

    to {
        transform: translateY(0px) scale(1.1);
    }
}

@-webkit-keyframes up-down {
    from {
        -webkit-transform: translateY(0px) scale(1.1);
    }

    50% {
        -webkit-transform: translateY(10px) scale(0.8);

    }

    to {
        -webkit-transform: translateY(0px) scale(1.1);
    }
}

@media screen and (max-width: 1450px) {
    .wrapper {
        width: 90%;
    }
}

@media screen and (max-width: 1250px) {
    .wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .container {
        margin-left: 0px;
    }
}

@media screen and (max-width: 600px) {
    .cardContainer {
        width: 80%;
    }
}

@media screen and (max-width: 450px) {
    .cardContainer {
        width: 90%;
    }
}
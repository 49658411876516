.container {
    height: 100vh;
    background-repeat: repeat;
    background-color: var(--hero);
    background-blend-mode: soft-light;
    width: 100%;
    object-fit: cover;
    position: relative;
    text-align: center;
    overflow: hidden;
    background-image: url('/src/images/polygon.png');

}
.image_404{
    margin:  0 auto;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40%;
}

@media screen and (max-width: 1400px) {
    .image_404 {
        height: 40%;
    }
}

@media screen and (max-width: 960px) {
    .image_404 {
        height: 30%;
    }
}

@media screen and (max-width: 700px) {

    .image_404 {
        height: 25%;
    }
}
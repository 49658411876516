.form_wrapper {
    position: relative;
    width   : 80%;
    margin  : auto;
}

.addIcon {
    position  : relative;
    display   : flex;
    margin    : auto !important;
    color     : var(--success);
    font-size : 32px;
    transition: 0.3s ease-in-out;
}

.addIcon:hover {
    cursor    : pointer;
    color     : var(--secondary);
    transform : scale(1.2);
    transition: 0.3s ease-in-out;
}

.close {
    position  : relative;
    display   : flex;
    margin    : auto !important;
    font-size : 32px;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
    transform : rotate(45deg);
    color     : var(--secondary);
}

.close:hover {
    cursor    : pointer;
    color     : var(--wrong);
    transform : scale(1.2) rotate(45deg);
    transition: 0.3s ease-in-out;
}


.files_container {
    margin         : auto;
    margin-top     : 1rem;
    margin-bottom  : 1rem;
    width          : 60%;
    box-shadow     : 2px 4px 4px 8px rgba(27, 27, 27, 0.171);
    -webkit-filter : drop-shadow(0 6px 20px rgba(146, 146, 146, 0.959));
    filter         : drop-shadow(0 6px 20px rgba(114, 114, 114, 0.678));
    border-radius  : 10px;
    overflow       : hidden;
    text-decoration: none;
    cursor         : grab;
    transition     : 0.2s ease-in-out;
}

.files_container:hover {
    transform : scale(1.1);
    transition: 0.2s ease-in-out;
}

.files_grid {
    display              : grid;
    grid-template-columns: repeat(3, auto);
    list-style           : none;
    text-decoration      : none;
}

.delete_icon {
    font-size  : 32px;
    color      : var(--wrong);
    transition : 0.2s ease-in-out;
    float      : left;
    padding-top: 10px;
    margin-left: 10px;
    cursor     : pointer;
    transform : rotate(180deg) scale(1.2) translateY(-10px);
    transition: 0.5s ease-in-out;
}

.delete_icon:hover {
    transform: scale(1.2);
    color     : var(--secondary);
}

.text {
    font-size: 32px;
}

.textAreaContainer {
    border-bottom: none;
}
.textArea {
    align-items  : center;
    width        : 90%;
    margin-top   : 2rem;
    font-size    : 25px;
    outline      : none;
    border       : none;
    border-radius: 10px 10px 10px 10px;
    height       : 250px;
    border       : 4px solid var(--main);
}


@media only screen and (max-width: 500px) {
    .form_wrapper {
        width: 90%;
    }
}
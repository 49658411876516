.personal-info-container {
    position     : relative;
    width        : 80%;
    margin       : auto;
    margin-top   : 5vh;
    margin-bottom: 5vh;
}

.personal-info-navbar-wrapper {
    border-radius  : 20px 20px 20px 20px;
    width          : 90%;
    margin         : auto;
    margin-bottom  : 2rem;
    position       : relative;
    justify-content: center;
    text-align     : center;
    align-items    : center;
}

.personal-info-navbar-wrapper>div {
    margin: auto;
    width : 85%;
}

.personal-info-navbar-wrapper>div>span {
    display       : inline-flex;
    margin        : auto;
    padding-bottom: 10px;
    padding       : 10px;
    cursor        : pointer;
    color         : var(--secondary);
    font-size     : 20px;
    transition    : 0.2s ease-in-out;

}

.personal-info-navbar-wrapper>div>span:hover {
    color     : var(--main);
    transform : translateY(-3px);
    transition: 0.2s ease-in-out;
}

.personal-info-navbar-wrapper>div>span.active {
    color           : white;
    border          : 2px solid var(--main);
    background-color: var(--main);
    border-radius   : 10px 10px 10px 10px;
}

.personal-info-navbar-wrapper>span>.drop-down-icon,
.personal-info-navbar-wrapper>div>.arrow-icon,
.personal-info-navbar-wrapper>div>.edit-icon,
.personal-info-navbar-wrapper>div>.add-icon {
    color         : var(--main);
    font-size     : 32px;
    text-align    : center;
    padding-bottom: 10px;
    margin-left   : 1rem;
    transition    : 0.3s ease-in-out;
}

.personal-info-navbar-wrapper>span>.drop-down-icon,
.personal-info-navbar-wrapper>div>.edit-icon {
    margin-left : 0;
    margin-right: 1rem;
}


.personal-info-navbar-wrapper>div>.arrow-icon.left {
    margin-right: 10px;
    margin-left : 0;
}

.personal-info-navbar-wrapper>div>.arrow-icon.arrow-icon.right {
    margin-left : 10px;
    margin-right: 0;
}

.personal-info-navbar-wrapper>span>.drop-down-icon:hover,
.personal-info-navbar-wrapper>div>.edit-icon:hover,
.personal-info-navbar-wrapper>div>.add-icon:hover {
    cursor        : pointer;
    color         : var(--secondary);
    transform     : rotate(360deg) scale(1.1);
    transition    : 0.3s ease-in-out;
    padding-bottom: 10px;
}

.personal-info-navbar-wrapper>div>.arrow-icon {
    color: var(--secondary)
}

.personal-info-navbar-wrapper>div>.arrow-icon:hover {
    cursor: pointer;
    color : var(--main);
}

.personal-info-navbar-wrapper>div>.arrow-icon.left:hover {
    transform: translateX(-5px) scale(1.1);
}

.personal-info-navbar-wrapper>div>.arrow-icon.right:hover {
    transform: translateX(5px) scale(1.1);
}

.personal-info-wrapper {
    text-decoration: none;
    border-radius  : 20px 20px 20px 20px;
    width          : 85%;
    position       : relative;
    margin         : auto;
    height         : calc(100% + 50px);
}

@media only screen and (max-width: 1300px) {

    .personal-info-navbar-wrapper,
    .personal-info-navbar-wrapper>div,
    .personal-info-container {
        width: 100%;
    }
}
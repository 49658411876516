.navbar {
    background-image: url('/src/images/footer-background.jpg');
    height          : 80px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    font-size       : 1.2rem;
    position        : sticky;
    top             : 0;
    z-index         : 999;
}

.navbar_manage {
    background      : rgb(255, 103, 1);
    background-image: url('/src/images/header_manage.png');
    height          : 80px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    font-size       : 1.2rem;
    position        : sticky;
    top             : 0;
    z-index         : 999;
}

.navbar_orange {
    height    : 5px;
    background: rgb(255, 103, 1);
    position  : sticky;
    display   : flex;
    z-index   : 999;
    top       : 80px;

}

.navbar_container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 80px;
    width          : 99%;
}

.logo_container {
    color          : #fff;
    justify-self   : start;
    margin-left    : 30px;
    cursor         : pointer;
    text-decoration: none;
    font-size      : 2rem;
    display        : flex;
    align-items    : center;
}

.logo {
    width     : 65px;
    transition: all 0.3s ease-in-out;
}

.logo_unimi {
    width     : 200px;
    transition: all 0.3s ease-in-out;
    transform : scale(1.1);
}

.logo_unimi:hover,
.logo:hover {
    transform: scale(1.2);
}



.nav_menu {
    display              : grid;
    grid-template-columns: repeat(6, auto);
    grid-gap             : 10px;
    list-style           : none;
    text-align           : center;
    width                : 60vw;
    justify-content      : end;
    margin-right         : 2rem;
    height               : 40px;
}

.nav_item {
    height: 80px;
}

.nav_links {
    color          : #fff;
    display        : inline-block;
    align-items    : center;
    text-decoration: none;
    padding        : 0.5rem 1rem;
    height         : 100%;
}

.nav_links:hover {
    color          : var(--main);
    text-decoration: none;
}

.nav_links::after {
    content   : '';
    display   : block;
    width     : 0;
    transition: width .3s ease-in-out;
}


.nav_links:hover::after {
    width          : 100%;
    color          : rgb(255, 103, 1);
    border-bottom  : 4px solid rgb(255, 103, 1);
    text-decoration: none;
}

.nav_links_manage {
    color          : #fff;
    display        : inline-block;
    align-items    : center;
    text-decoration: none;
    padding        : 0.5rem 1rem;
    height         : 100%;
}

.nav_links_manage:hover {
    color          : var(--secondary);
    text-decoration: none;
}

.nav_links_manage::after {
    content   : '';
    display   : block;
    width     : 0;
    transition: width .3s ease-in-out;
}

.nav_links_manage:hover::after {
    width          : 100%;
    color          : #5c5c5c;
    border-bottom  : 4px solid #5c5c5c;
    transition     : all 0.2s ease-out;
    text-decoration: none;
}


.nav_links_mobile {
    display: none;
}

.nav_menu_icon {
    display: none;
}

.span {
    margin-left: 16px;
}

@media screen and (max-width: 960px) {
    .nav_menu {
        background-image: url('/src/images/footer-background_rotate.jpg');
        display         : flex;
        flex-direction  : column;
        width           : 100%;
        height          : 95vh;
        position        : absolute;
        top             : 60px;
        left            : -100%;
        opacity         : 1;
        transition      : all 0.5s ease;
    }

    .wrap_nav_menu {
        display       : flex;
        flex-direction: column;
        width         : 100%;
        position      : absolute;
        top           : 0;
        opacity       : 1;
        transition    : all 0.5s ease;
        left          : 0;
    }

    .nav_menu_manage {
        background-image: url('/src/images/header_manage_rotate.png');
    }

    .nav_menu_active {
        background-image: url('/src/images/footer-background_rotate.jpg');
        left            : 0;
        opacity         : 1;
        transition      : all 0.5s ease;
        z-index         : 1;
    }

    .nav_menu_active_manage {
        background-image: url('/src/images/header_manage_rotate.png');
    }


    .nav_links {
        text-align: center;
        padding   : 2rem;
        width     : 100%;
        display   : table;
    }

    .nav_links:hover {
        background-color: #fff;
        color           : rgb(255, 103, 1);
        border-bottom   : 4px solid rgb(255, 103, 1);
        border-radius   : 0;
    }

    .nav_links_manage {
        text-align: center;
        padding   : 2rem;
        width     : 100%;
        display   : table;

    }

    .my__nav__links__manage:hover {
        background-color: #fff;
        color           : #5c5c5c;
        border-bottom   : 4px solid #5c5c5c;
        transition      : all 0.2s ease-out;
    }

    .logo_container {
        position : absolute;
        top      : 0;
        left     : 0;
        transform: translate(0%, 10%);
    }

    .logo_unimi_container {
        position : absolute;
        top      : 0;
        left     : 0;
        transform: translate(60%, 5%);
    }

    .logo_unimi {
        width: 180px;
    }

    .nav_menu_icon {
        display  : block;
        position : absolute;
        top      : 0;
        right    : 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor   : pointer;
    }

    .nav_links_mobile {
        display         : block;
        text-align      : center;
        margin          : 2rem auto;
        border-radius   : 4px;
        width           : 80%;
        text-decoration : none;
        font-size       : 1.5rem;
        background-color: transparent;
        color           : #fff;
        padding         : 14px 20px;
        border          : 1px solid #fff;
        transition      : all 0.3s ease-out;
    }

    .nav_links_mobile:hover {
        background: #fff;
        color     : #242424;
        transition: 250ms;
    }

    .burgerIcon {
        outline            : none;
        border             : none;
        -webkit-user-select: none;
        -moz-user-select   : none;
        -ms-user-select    : none;
        cursor             : pointer;
        position           : absolute;
        width              : 60px;
        height             : 60px;
        border-radius      : 50%;
        color              : var(--primary);
        background         : transparent;
        right              : 0;
        margin-top         : 10px;
    }
}


@media screen and (max-width: 360px) {
    .logo_container {
        position : absolute;
        top      : 0;
        left     : 0;
        transform: translate(0%, 35%);
    }

    .logo_unimi_container {
        position : absolute;
        top      : 0;
        left     : 0;
        transform: translate(70%, 20%);
    }

    .logo {
        width     : 45px;
        transition: all 0.3s ease-in-out;
    }

    .logo_unimi {
        width     : 140px;
        transition: all 0.3s ease-in-out;
        transform : scale(1.1);
    }
}
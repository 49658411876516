.container {
    cursor         : pointer;
    margin         : auto;
    margin-top     : 10px;
    margin-bottom  : 10px;
    padding        : 5px;
    width          : 90%;
    box-shadow     : 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.19);
    transform-style: preserve-3d;
    cursor         : pointer;
    position       : relative;
    transition     : all 0.4s ease-in-out;
    
}

.container:hover {
    background: rgba(0, 0, 0, 0.199);
    cursor    : pointer;
    transform : scale(1.05);
}

.container.flip {
    transform: rotateY(180deg);    
}


.card_face {
    position                   : absolute;
    height                     : 100%;
    width                      : 95%;
    -webkit-backface-visibility: hidden;
    backface-visibility        : hidden;
    overflow                   : hidden;
}


.front {
    align-items    : flex-start;
    justify-content: start;
}

.title {
    font-size  : var(--font-subtitle);
    font-weight: 900;
}


.back {
    transform      : rotateY(180deg);
    align-items    : center;
    justify-content: center;
}

.icon {
    color: rgb(255, 103, 1);
    margin-right: 5px;
    margin-bottom: 5px;

}

.date{
    bottom : 0;
    right: 0;
    margin-bottom: 5px;
    position: absolute;
}

.link{
    position: absolute;
    color: var(--main);
    z-index: 99999;

}

.link:hover {
    position: absolute;
    color: var(--secondary);
    z-index: 99999;
}

@media only screen and (max-width:1500px) {
    .container{
        height: 30%;
    }
    .title {
        font-size  : 24px;
    }
}
@media only screen and (max-width:1400px) {
    .container{
        height: 31%;
    }
    .title {
        font-size  : 23px;
    }
}

@media only screen and (max-width:1300px) {
    .container{
        height: 32%;
    }
    .title {
        font-size  : 22px;
    }
}


@media only screen and (max-width:1200px) {
    .container{
        height: 33%;
    }
    .title {
        font-size  : 21px;
    }
}

@media only screen and (max-width:960px) {
    .container{
        height: 25%;
    }
    .title {
        font-size  : 25px;
    }
}

@media only screen and (max-width:600px) {
    .container{
        height: 30%;
    }
    .title {
        font-size  : 24px;
    }
}
@media only screen and (max-width:500px) {
    .container{
        height: 31%;
    }
    .title {
        font-size  : 23px;
    }
}

@media only screen and (max-width:400px) {
    .container{
        height: 32%;
    }
    .title {
        font-size  : 22px;
    }
}
@media only screen and (max-width:350px) {
    .container{
        height: 33%;
    }
    .title {
        font-size  : 21px;
    }
}




.alert-background {
    display : block;
    position: fixed;
    left    : 250px;
    top     : 20px;
    overflow: auto;
    z-index : 9999;
    overflow: hidden;
}

.alert-background.manage {
    width: calc(100% - 250px);
}

.alert-wrapper {
    font-size    : 30px;
    position     : relative;
    background   : var(--primary);
    border-radius: 5px 5px 5px 5px;
    margin       : auto;
    width        : 40%;
    border       : 1px solid rgba(0, 0, 0, 0.19);
    z-index      : 9999;
}

.alert-header {
    position     : relative;
    display      : inline-block;
    width        : 100%;
    padding      : 2px 16px;
    border-radius: 5px 5px 0 0;
    color        : var(--secondary);
    text-align   : left;
}

.alert-header.warning {
    background: var(--warning);
}

.alert-body {
    position       : relative;
    margin         : auto;
    width          : 100%;
    justify-content: center;
    align-items    : center;
    text-align     : center;
    padding    : 1rem;
}

.alert-close-button {
    color      : #fff;
    right      : 5px;
    font-size  : 38px;
    font-weight: bold;
    position   : absolute;
    z-index    : 10;
    transition : 0.5s ease-in-out;
}

.alert-close-button:hover,
.alert-close-button:focus {
    color          : var(--secondary);
    transform      : rotate(180deg);
    text-decoration: none;
    cursor         : pointer;
}

.alert-header.warning .alert-close-button {
    color: var(--secondary);
}

.alert-header.warning .alert-close-button:hover {
    color: var(--primary);
}

.alertEnter {
    animation                  : alertEnter 2s;
    -webkit-animation          : alertEnter 2s;
    animation-fill-mode        : forwards;
    -webkit-animation-fill-mode: forwards;
}

.alertExit {
    animation                  : alertExit 0.5s;
    -webkit-animation          : alertExit 0.5s;
    animation-fill-mode        : forwards;
    -webkit-animation-fill-mode: forwards;
}

/* Add Animation */
@keyframes moveAlert {
    from {
        -webkit-transform: translate(-100%, 0);
        opacity          : 0
    }

    10% {
        -webkit-transform: translate(30px, 0);
        opacity          : 1;
        transform        : skew(-0.06turn, 1deg);
    }

    12% {
        -webkit-transform: translate(25px, 0);
        transform        : skew(0.03turn, 0deg);
    }

    16% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(0.0turn, 0deg);
    }

    84% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(0.0turn, 0deg);
    }

    88% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(-0.03turn, 0.0deg);
    }

    90% {
        -webkit-transform: translate(30px, 0);
        opacity          : 1;
        transform        : skew(0.06turn, -1deg);
    }

    to {
        -webkit-transform: translate(-100%, 0);
        opacity          : 0;
    }
}

@-webkit-keyframes moveAlert {
    from {
        -webkit-transform: translate(-100%, 0);
        opacity          : 0
    }

    10% {
        -webkit-transform: translate(30px, 0);
        opacity          : 1;
        transform        : skew(-0.06turn, 1deg);
    }

    12% {
        -webkit-transform: translate(25px, 0);
        transform        : skew(0.03turn, 0deg);
    }

    16% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(0.0turn, 0deg);
    }

    84% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(0.0turn, 0deg);
    }

    88% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(-0.03turn, 0.0deg);
    }

    90% {
        -webkit-transform: translate(30px, 0);
        opacity          : 1;
        transform        : skew(0.06turn, -1deg);
    }

    to {
        -webkit-transform: translate(-100%, 0);
        opacity          : 0;
    }
}


@keyframes alertEnter {
    from {
        -webkit-transform: translate(-100%, 0);
        opacity          : 0
    }

    10% {
        -webkit-transform: translate(30px, 0);
        opacity          : 1;
        transform        : skew(-0.06turn, 1deg);
    }

    12% {
        -webkit-transform: translate(25px, 0);
        transform        : skew(0.03turn, 0deg);
    }

    16% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(0.0turn, 0deg);
    }

    to {
        -webkit-transform: translate(0, 0);
        opacity          : 1;
    }
}

@keyframes alertExit {
    from {
        -webkit-transform: translate(0, 0);
        opacity          : 1
    }

    10% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(0.0turn, 0deg);
    }

    12% {
        -webkit-transform: translate(20px, 0);
        transform        : skew(-0.03turn, 0.0deg);
    }

    16% {
        -webkit-transform: translate(30px, 0);
        opacity          : 1;
        transform        : skew(0.06turn, -1deg);
    }

    to {
        -webkit-transform: translate(-100%, 0);
        opacity          : 0;
    }
}


@media only screen and (max-width: 1200px) {
    .alert-wrapper {
        width: 50%;
    }
}

@media only screen and (max-width: 960px) {
    .alert-background.manage {
        left : 0;
        width: 100%;
    }

    .alert-background-sidebar {
        display: none;
    }

    .alert-wrapper {
        font-size: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .alert-wrapper {
        width: 70%;
    }
}

@media only screen and (max-width: 460px) {
    .alert-wrapper {
        border: none;
    }
}
.card {
    margin       : 50px auto 0;
    width        : 250px;
    height       : 350px;
    perspective  : 1000px;
    border-radius: 10px;
    background   : transparent;
    transition   : all 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
}



.card_inner {
    width              : 100%;
    height             : 100%;
    transition         : transform 1s;
    transform-style    : preserve-3d;
    cursor             : pointer;
    position           : relative;
    box-shadow         : 10px 10px 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.19);
    /* background-image: url('/home/daniele/Lavoro/islab/src/images/card_bg.png'); */
    border-radius      : 10px;
}

.is_flipped {
    transform: rotateY(540deg);
}

.card_face {
    position                   : absolute;
    width                      : 100%;
    height                     : 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility        : hidden;
    overflow                   : hidden;
    border-radius              : 16px;
    box-shadow                 : 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
    border-radius              : 10px;
}

.card_face_front {
    align-items    : center;
    justify-content: center;
}

.card_pic_wrap {
    position     : relative;
    overflow     : hidden;
    min-height   : 210px;
    max-height   : 210px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0.5rem;
}
.card_pic_wrap_flip {
    position     : relative;
    min-height   : 104px;
    max-height   : 104px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0.5rem;
}

.card_pic {
    position  : relative;
    overflow  : hidden;
    margin    : auto;
    max-width : 200px;
    max-height: 200px;
    display   : block;
    margin-top: 42.5%;
    Transform : translateY(-50%);
}

.card_title {
    color         : var(--main);
    font-size     : 20px;
    font-weight   : 900;
    text-transform: uppercase;
    text-align    : center;
}

.card_title:hover {
    text-decoration: none;
}

.card_subtitle {
    padding: 5%;
}

.card_face_back {
    transform       : rotateY(-180deg);
    background-image: linear-gradient(to top right, var(--main) 110%, var(--secondary) 115%);
    justify-content : center;
    text-align      : center;
}



.card_link {
    color         : white;
    font-size     : large;
    font-weight   : 900;
    text-transform: uppercase;
    margin-top    : 1rem;
    display       : inline-block;
}

.card_link:hover {
    color: var(--secondary);

}

.card_no_link {
    color         : white;
    font-size     : 20px;
    font-weight   : 900;
    text-transform: uppercase;
    text-align    : center;
    margin-top    : 1rem;
    display       : inline-block;
    align-items   : center;
}

.card_link::after {
    content      : '';
    display      : block;
    width        : 0;
    border-bottom: 4px solid var(--secondary);
    transition   : width .3s ease-in-out;
}


.card_link:hover::after {
    width          : 95%;
    color          : var(--secondary);
    border-bottom  : 4px solid var(--secondary);
    text-decoration: none;
}

.card_content_sub {
    position : relative;
    color    : rgb(26, 26, 26);
    width    : 100%;
    margin   : auto;
    font-size: 16px;
    padding  : 20px;
}


.card_content {
    position    : relative;
    width       : 100%;
    height      : 250px;
    margin      : auto;
    overflow-Y  : auto;
    padding     : 10px;
    text-align  : left;
    text-justify: auto;
}

.card_content_desc {
    position : relative;
    color    : #fff;
    width    : 100%;
    margin   : auto;
    font-size: 14px;
    padding  : 20px;
}
.container {
    position      : relative;
    width         : 100%;
    min-height    : 90vh;
    margin        : auto;
    margin-top    : 5vh;
    margin-bottom : 5vh;
    padding-bottom: 20px;
}

.name {
    text-align   : center;
    color        : rgb(255, 103, 1);
    font-size    : 50px;
    margin-bottom: 1rem;
}



.nav_wrapper {
    position: relative;
    margin  : auto;
    width   : 70%;
}


.nav_items {
    width         : 90%;
    margin        : auto;
    text-align    : center;
    display       : inline-block;
    margin        : auto;
    margin-top    : 1rem;
    padding-bottom: 10px;
}


.arrow {
    position     : absolute;
    outline      : none;
    transition   : all 0.5s;
    border-radius: 35px;
    z-index      : 100;
    border       : none;
    background   : rgba(0, 0, 0, 0.39);
    min-width    : 43px;
    min-height   : 43px;
    opacity      : 1;
    cursor       : pointer;
}

.arrow:hover {
    background: var(--main);
    opacity   : 0.8;
}

.arrow::before {
    font-size  : 20px;
    color      : #fff;
    display    : block;
    font-family: revicons;
    text-align : center;
    z-index    : 2;
    position   : relative;
}

.arrowLeft {
    left: calc(4% + 1px);
}

.arrowLeft:hover {
    transform                        : scale(1.2);
    animation                        : right-left 1s ease-in-out;
    -webkit-animation                : right-left 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.arrowLeft::before {
    content: "\e824";
}

.arrowRight {
    right: calc(4% + 1px);
}

.arrowRight:hover {
    transform                        : scale(1.2);
    animation                        : left-right 1s ease-in-out;
    -webkit-animation                : left-right 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.arrowRight::before {
    content: "\e825";
}

@keyframes left-right {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}

@keyframes right-left {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(-10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}

.wrapper {
    border-radius: 20px 20px 20px 20px;
    width        : 85%;
    margin       : auto;
    margin-bottom: 5vh;
    position     : relative;
    border       : 5px solid var(--main);
}

.item {
    padding  : 10px;
    cursor   : pointer;
    color    : #333;
    font-size: 20px;
}

.item:hover {
    color    : var(--main);
    transform: translateY(10px);
}

.item_active {
    padding         : 10px;
    cursor          : pointer;
    font-size       : 20px;
    color           : white;
    border          : 2px solid var(--main);
    background-color: var(--main);
    border-radius   : 10px 10px 0 0;
}


.description_container {
    margin   : 2rem auto 2rem auto;
    width    : 90%;
    
}
.description{
    margin   : 2rem auto 2rem auto;
    font-size: 20px;
}
 a{
    color : var(--main);
    text-decoration: none;
}

 a:hover{
    color : var(--secondary);
    text-decoration: none;
}

.user_title {
    color     : var(--main);
    font-size : 35px;
    text-align: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 30% 70%);
    width: 95%;
    margin: auto;
    align-items: baseline;
}


@media only screen and (max-width: 1300px) {
    .wrapper {
        width: 90%;
    }
}

@media only screen and (max-width: 960px) {
    .wrapper {
        width: 100%;
        border: none;
    }
    .grid {
        grid-template-columns: repeat(1, auto);
        
    }
}
@media only screen and (max-width: 600px) {
    
}


.card {
    width          : 250px;
    height         : 300px;
    display        : flex;
    flex-flow      : column;
    border         : 2px solid rgba(63, 63, 63, 0.158);
    box-shadow     : 10px 10px 20px 20px rgba(252, 80, 0, 0.17);
    -webkit-filter : drop-shadow(0 6px 20px rgba(221, 18, 18, 0.017));
    filter         : drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius  : 10px;
    overflow       : hidden;
    text-decoration: none;
    cursor         : pointer;
    transition     : all 0.2s linear;
}

.card:hover {
    transform : scale(1.05);
    box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.281);
}

.move {
    cursor: grab;
}

.move:active {
    cursor: grabbing;
}


.card:hover .icon_btn {
    opacity   : 1;
    transition: all 0.5s ease-in-out;
    transform : translate(-210px);

}

.icon_btn {
    opacity    : 0;
    font-size  : 30px;
    position   : absolute;
    z-index    : 1;
    transition : all 0.5s ease-in-out;
    right      : 0;
    padding-top: 5px;

}

.icon_btn:hover {
    color: var(--wrong);
}


.pic_wrap {
    position     : relative;
    width        : 100%;
    padding-top  : 70%;
    border-radius: 5px 5px 0px 0px;
    overflow     : hidden;
}

.pic_wrap::after {
    content         : attr(data-category);
    position        : absolute;
    bottom          : 0;
    left            : 10px;
    padding         : 4px 16px;
    max-width       : calc((100%) - 60px);
    font-size       : 14px;
    font-weight     : 700;
    color           : #fff;
    border-radius   : 5px 5px 0px 0px;
    background-color: transparent;
    box-sizing      : border-box;
}

.pic {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    display   : block;
    width     : 100%;
    max-width : 100%;
    height    : 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.pic:hover {
    transform: scale(1.1);
}

.title{
    color      : var(--secondary);
    font-size  : 20px;
    line-height: 24px;
    text-align : center;
    padding    : 1rem;
    transition: all 0.2s linear;
}

.card:hover .title{
    color      : var(--main);
    font-size  : 25px;
}




@media screen and (max-width: 960px) {

    .cards-container {
        padding-left: 0;
        width       : calc(80%);
    }

    .cards-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .cards-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .cards-container {
        text-align: center;

    }
}
.form-wrapper {
    position: relative;
    width   : 80%;
    margin  : auto;
}

.icon {
    font-size    : 25px;
    color        : var(--main);
    margin-right : 1rem;
    margin-bottom: 10px;

}

.icon.correct {
    float       : right;
    margin-top  : 2.5rem;
    margin-right: 0;
    font-size   : 20px;
    color       : var(--success);
}

.icon.wrong {
    float       : right;
    margin-top  : 2.5rem;
    margin-right: 0;
    font-size   : 20px;
    color       : var(--wrong);
}

.icon.warning {
    float       : right;
    margin-top  : 2.5rem;
    margin-right: 0;
    font-size   : 20px;
    color       : var(--warning);
}


.input-field {
    position     : relative;
    margin       : auto;
    outline      : 0;
    text-align   : center;
    border-bottom: 4px solid var(--main);
    width        : 100%;
    text-align   : left;
}

.input-field.no-border {
    border-bottom: none;
}

.input-field>input {
    margin-top: 2rem;
    font-size : var(--font-text);
    outline   : none;
    border    : none;
    width: 60%;
}

.input-field>textarea {
    min-width    : 90%;
    margin-top   : 2rem;
    font-size : var(--font-text);
    outline      : none;
    border       : none;
    border-radius: 10px 10px 10px 10px;
    height       : 250px;
    border       : 4px solid var(--main);
    resize: none;

}

.input-error {
    position: absolute;
    color   : var(--wrong);
    left    : 0;

}

.error-message {
    color: var(--wrong);
}


.input-switch {
    position  : relative;
    display   : inline-flex;
    /* margin    : auto; */
    margin-top: 1rem;
    outline   : 0;
    width     : 100%;
    text-align: left;
}

.onoffswitch {
    position           : relative;
    width              : auto;
}

.onoffswitch-checkbox {
    position      : absolute;
    opacity       : 0;
    pointer-events: none;
}

.onoffswitch-label {
    display      : block;
    overflow     : hidden;
    cursor       : pointer;
    border       : 2px solid var(--secondary);
    border-radius: 50px;
}

.onoffswitch-inner {
    display    : block;
    width      : 200%;
    margin-left: -100%;
    transition : margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display    : block;
    float      : left;
    width      : 50%;
    height     : 31px;
    padding    : 0;
    line-height: 30px;
    font-size  : 14px;
    color      : white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing : border-box;
}

.onoffswitch-inner:before {
    content         : attr(content_t);
    padding-left    : 15px;
    background-color: var(--main);
    color           : #FFFFFF;
}

.onoffswitch-inner:after {
    content         : attr(content_f);
    padding-right   : 15px;
    background-color: #EEEEEE;
    color           : var(--secondary);
    text-align      : right;
}

.onoffswitch-switch {
    /* cursor */
    display      : block;
    width        : 40px;
    margin       : -2.5px;
    background   : #FFFFFF;
    position     : absolute;
    top          : 0;
    bottom       : 0;
    right        : 50%;
    border       : 2px solid var(--secondary);
    border-radius: 50px;
    transition   : all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

@media only screen and (max-width: 600px) {
    .input-field>input {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {
    .icon {
        font-size   : 20px;
        margin-right: 10px;
    }

    .form-wrapper {
        width: 90%;
    }

    .input-field {
        font-size: 18px;
    }
    .onoffswitch-switch {
        right : 54%
    }

    

}
.cardsWrapper {
    margin               : auto;
    display              : grid;
    grid-gap             : 3rem;
    grid-template-columns: repeat(3, auto);
    grid-auto-rows: auto;
    width                : 90%;
    margin-top: 0;
}

.cardMore,
.card {
    width          : 260px;
    height         : 350px;
    display        : flex;
    flex-flow      : column;
    border         : 2px solid rgba(63, 63, 63, 0.158);
    box-shadow     : 5px 5px 10px 10px rgba(2, 2, 2, 0.17);
    -webkit-filter : drop-shadow(0 6px 20px rgba(221, 18, 18, 0.017));
    filter         : drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius  : 10px;
    overflow       : hidden;
    text-decoration: none;
    cursor         : pointer;
    transition     : all 0.2s linear;
    margin         : auto;
}

.cardMore {
    border        : none;
    box-shadow    : none;
    -webkit-filter: none;
    filter        : none;
    text-align    : center;
}

.cardMore>.text {
    margin-top     : 50%;
    font-size      : 50px;
    transition     : all 0.2s ease-in-out;
    text-decoration: none;
    color          : var(--secondary);
}

.cardMore>.text:hover {
    color          : var(--main);
    transform      : scale(1.1);
    text-decoration: none;
}

.imgWrap {
    position     : relative;
    overflow     : hidden;
    min-height   : 210px;
    max-height   : 210px;

}
.img {
    position  : relative;
    overflow  : hidden;
    margin    : auto;
    max-width : 200px;
    max-height: 200px;
    display   : block;
    margin-top: 42.5%;
    Transform : translateY(-50%);
}

.title {
    padding-top: 1%;
    color      : var(--main);
    font-size  : 25px;
    text-align : center;
}

.subtitle {
    padding: 5%;
}



@media only screen and (max-width: 1300px) {
    .cardsWrapper {
        grid-template-columns: repeat(2, auto);
    }
}


@media only screen and (max-width: 650px) {
    .cardsWrapper {
        grid-template-columns: repeat(1, auto);
    }


}
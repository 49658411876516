.project__container {
    flex-flow     : column;
    align-items   : center;
    width         : 95%;
    margin        : 0 auto;
    padding-bottom: 50px;


}

.project__wrapper {
    display              : grid;
    grid-template-columns: repeat(4, auto);
    justify-content      : space-evenly;
    align-items          : center;
    margin               : 50px 0 250px;


}

.project__card {
    margin       : 50px auto 0;
    width        : 300px;
    height       : 400px;
    perspective  : 1000px;
    border-radius: 10px;
    background   : transparent;
    transition   : all 0.3s ease;
}

.project__card:hover {
    transform: scale(1.05);
}

.project__card__inner {
    width              : 100%;
    height             : 100%;
    transition         : transform 1s;
    transform-style    : preserve-3d;
    cursor             : pointer;
    position           : relative;
    box-shadow         : 10px 10px 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.19);
    /* background-image: url('/home/daniele/Lavoro/islab/src/images/card_bg.png'); */
    border-radius      : 10px;
}

.project__card__inner.is-flipped {
    transform: rotateY(540deg);
}

.project__card__face {
    position                   : absolute;
    width                      : 100%;
    height                     : 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility        : hidden;
    overflow                   : hidden;
    border-radius              : 16px;
    box-shadow                 : 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
    border-radius              : 10px;
}

.project__card__face--front {

    align-items    : center;
    justify-content: center;
}

.project__card__pic-wrap {
    overflow     : hidden;
    max-width    : 300px;
    max-height   : 300px;
    left         : 0;
    top          : 0;
    border-radius: 0px 0px 50% 0px;
}

.project__card__title {
    color         : var(--main);
    font-size     : min(max(32px), 22px);
    font-weight   : 900;
    text-transform: uppercase;
    text-align    : center;
    margin-top    : 1rem;
}

.project__card__title:hover {
    text-decoration: none;
}

.project__card__face--back {
    transform       : rotateY(-180deg);
    background-image: linear-gradient(to top right, var(--main) 110%, var(--secondary) 115%);
    justify-content : center;
    text-align      : center;
}

.project__card__header {
    margin: 1rem auto 1rem 1rem;

}

.project__card__link {
    color         : white;
    font-size     : 32px;
    font-weight   : 900;
    text-transform: uppercase;
    text-align    : center;
    margin-top    : 1rem;
}

.project__card__link:hover {
    color: var(--secondary);
}

.project__card__content {
    position  : relative;
    height    : 280px;
    color     : #fff;
    width     : 100%;
    margin    : auto;
    text-align: justify;
    font-size : 20px;
    overflow-Y: auto;
    padding   : 20px;
}






@media only screen and (max-width: 1400px) {
    .project__wrapper {
        grid-template-columns: repeat(3, auto);
    }
}



@media only screen and (max-width: 1100px) {
    .project__wrapper {
        display              : grid;
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 750px) {
    .project__wrapper {
        display              : grid;
        grid-template-columns: repeat(1, auto);
    }
}